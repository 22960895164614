
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCu8JVixUjpBoEdkDj3iYgAGqbg5CVAtaU",
  authDomain: "vscodeasyncvideo.firebaseapp.com",
  projectId: "vscodeasyncvideo",
  storageBucket: "vscodeasyncvideo.appspot.com",
  messagingSenderId: "860410295190",
  appId: "1:860410295190:web:c8eefd9cea6995fe58a3b7"
};


const app = initializeApp(firebaseConfig);


export default { firebaseApp: app };