import React, { useState } from 'react';
import ContactUsModal from './contact_us_modal/ContactUsModal';
import Footer from './footer/Footer';
import ScreenOne from './screen_1/ScreenOne';
import ScreenTwo from './screen_2/ScreenTwo';
import ScreenThree from './screen_3/ScreenThree';
import SignupModal from './signup_modal/SignupModal';

interface IProps {
  isMobile: boolean,
  modalToggle: boolean,
  setModalToggle: Function,
  productRef: React.Ref<HTMLDivElement>,
  featuresRef: React.Ref<HTMLDivElement>,
  pricingRef: React.Ref<HTMLDivElement>,
  handleNavScroll: Function,
  setFeaturesSectionVisible: Function,
}

const AppWindow : React.FC<IProps> = ({isMobile, modalToggle, setModalToggle, productRef, featuresRef, pricingRef, handleNavScroll, setFeaturesSectionVisible} : IProps) => {

  const [ contactUsModalToggle, setContactUsModalToggle ] = useState<boolean>(false);
  const [ proposedPlan, setProposedPlan ] = useState<string>("");

    
  return (
    <div className="absolute w-screen flex flex-col justify-center items-center bg-vs-grey-4" style={{top: "60px"}}> 
      {modalToggle && <SignupModal modalToggle={modalToggle} setModalToggle={setModalToggle} proposedPlan={proposedPlan} />}
      {contactUsModalToggle && <ContactUsModal contactUsModalToggle={contactUsModalToggle} setContactUsModalToggle={setContactUsModalToggle}/>  }
      <div className="w-screen flex flex-col items-center justify-center">
        <ScreenOne isMobile={isMobile} setModalToggle={setModalToggle} productRef={productRef}/>
        <ScreenTwo featuresRef={featuresRef} setFeaturesSectionVisible={setFeaturesSectionVisible}/>
        <ScreenThree pricingRef={pricingRef} setModalToggle={setModalToggle} setProposedPlan={setProposedPlan} />
        <Footer handleNavScroll={handleNavScroll} setContactUsModalToggle={setContactUsModalToggle}/>
      </div>
    </div>
  )
}

export default AppWindow