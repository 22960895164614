import React from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { motion } from 'framer-motion';

interface IProps {
  pricingRef: React.Ref<HTMLDivElement>,
  setModalToggle: Function,
  setProposedPlan: Function,
}

const cardVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};



const ScreenThree : React.FC<IProps> = ({pricingRef, setModalToggle, setProposedPlan} : IProps ) => {

  const plans = [
    {
      planName: "Starter", 
      description: "Let's get you started",
      price: "Free",
      colorScheme: "bg-vs-purple-1",
      includes:   [
        "Single user",
        // "Video recording",
        // "Audio recording",
        // "Message threads",
      ]
    },
    {
      planName: "Scale Up", 
      description: "The essential plan",
      price: "4.95",
      colorScheme: "bg-vs-blue-3",
      includes:   [
        "Teams of up to 5",
        // "Video recording",
        // "Audio recording",
        // "Message threads",
      ]
    },
    {
      planName: "Enterprise", 
      description: "For larger teams",
      price: "9.99",
      colorScheme: "bg-vs-green-2",
      includes:   [
        "Unlimited team size",
        // "Video recording",
        // "Audio recording",
        // "Message threads",
      ]
    },
  ]

  function handlePlanChoice(planName: string){
    setProposedPlan(planName);
    setModalToggle(true);
  }

  return (
    <div className='flex-col flex items-center pt-24 pb-24' ref={pricingRef}>
      <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='text-2xl mt-6 font-extrabold text-white'>Find The Right Plan For You</motion.div>
      <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='text-lg my-3 font-md mt-6 text-zinc-200 text-center p-2'>It's super simple. Are you a Lone Wolf, a Small Team or a Large Company?</motion.div>
      {/* <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='text my-3 mt-0 font-extralight text-zinc-200'>Prices in GBP</motion.div> */}
      <div className='flex flex-col lg:flex-row'>
        {
          plans.map(plan => (
            <motion.div key={plan.planName} initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className="bg-vs-grey-2 flex flex-col items-stretch justify-between pb-6 border-2 drop-shadow-xl border-zinc-500 m-6" style={{height: "220px", width: "250px", borderRadius: "10px"}}>
              <div>
                <div className={`text-zinc-800 text-xl font-extrabold w-full flex justify-center p-2 mb-3 ${plan.colorScheme}`} style={{borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>{plan.planName}</div>
                <div className='text-white text-xl font-light w-full flex justify-center p-2 text-center'>{plan.description}</div>
                {/* <div className='flex w-full flex-row items-center justify-center'>
                  { plan.planName !== "Starter" && <div className='text-zinc-500 m-1 font-extralight text-3xl'>£</div>}
                  <div className='text-white font-light m-3 text-4xl'>{plan.price}</div>
                  { plan.planName !== "Starter" && 
                  <div className='flex flex-col text-zinc-500'>
                    <div className='text-sm font-extralight'>per user</div>
                    <div className='text-sm font-extralight'>per month</div>
                  </div>}
                </div> */}
                <div className='p-3 flex flex-col'>
                  <div>
                    {
                      plan.includes.map((feature, index) => (
                        <div key={index} className='w-full flex flex-row font-extralight text-zinc-400 border-b border-zinc-700 pb-2 mb-2'>
                            <span className='mr-3 text-zinc-400'><BsCheck2 size={22} /></span>
                            <span className="text-zinc-300">
                              {
                               index === 0 ?
                               <b>{feature}</b>
                               :
                               feature 
                              }
                            </span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className='flex justify-center' style={{ width: "250px"}}>
                <div onClick={()=>handlePlanChoice(plan.planName)} className={`rounded-lg flex justify-center items-center text-zinc-800 hover:opacity-50 cursor-pointer ${plan.colorScheme}`} style={{height: "35px", width: "210px"}}>
                  Choose {plan.planName}
                </div>
              </div>
            </motion.div>
          ))
        }
      </div>
    </div>
  )
}

export default ScreenThree