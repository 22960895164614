import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BsChevronDown } from 'react-icons/bs';

interface IProps {
  isMobile: boolean,
  setModalToggle: Function,
}

const IntroductionMessage : React.FC<IProps> = ({isMobile, setModalToggle} : IProps) => {

  const cardVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  const whyMessage = [
    "Ekho makes developing your developers effortless.",
    "Ekho enables seamless development when working across time schedules.",
    "Does documentation bore you to tears?"
  ]

  return (
    <div className="w-fit self-centerflex mb-0 p-3 flex flex-col items-center justify-start" style={{zIndex: 10}}>
        {/* <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className="text-5xl mb-12 text-white text-center font-extralight m-3">{whyMessage[1]}</motion.div> */}
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className="text-3xl font-semibold m-3 text-center text-vs-blue-1 ">A Visual Studio Code extension that allows you to communicate through your code editor.</motion.div>
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className="text-3xl text-white text-center font-extralight m-3">Async audio, video, and messaging located exactly where you need it.</motion.div>
        {
          isMobile && <BsChevronDown size={32} color={'white'}/>
        }
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className="text-xl text-white font-extralight m-3">Pre-registration now open.</motion.div>
        <motion.div onClick={()=>setModalToggle(true)} initial="offscreen" whileInView="onscreen" whileHover={{scale: 1.1}} variants={cardVariants} viewport={{ once: true, amount: 0.2 }}  className="mr-6 ml-6 px-6 py-2 text-white text-sm font-extralight drop-shadow-2xl rounded-full bg-vs-green-1 hover:bg-vs-green-2 cursor-pointer ">Sign me up</motion.div>
    </div>
  )
}

export default IntroductionMessage