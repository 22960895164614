import { useEffect, useState } from "react";
import UnAuthenticatedRoute from "./unAuthenticated/UnAuthenticatedRoute";
import CookieConsent, {getCookieConsentValue, Cookies} from "react-cookie-consent";


//Google Analytics
import ReactGA from "react-ga4";
// const TRACKING_ID = "G-PZZWQ7Q6ZZ";
// ReactGA.initialize(TRACKING_ID);

export const initGA = (id: string) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(id);
  }
};

interface IProps {
  
}

const App : React.FC<IProps> = ({} : IProps) => {

  

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile : boolean = width <= 768;

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);
  

  return (
    <div>
      <UnAuthenticatedRoute isMobile={isMobile}/>
      <CookieConsent 
              enableDeclineButton onAccept={handleAcceptCookie} 
              onDecline={handleDeclineCookie} 
              buttonStyle={{ backgroundColor: "green", borderRadius: "50px", color: "white", paddingLeft: "20px", paddingRight: "20px", paddingTop: '10px', paddingBottom: "10px", fontWeight: "200" }}
              declineButtonStyle={{ backgroundColor: "rgba(0, 0, 0, 0)", borderRadius: "5px", color: "#e67067", fontWeight: "200" }}
              declineButtonText="Reject"
              >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
