import React from 'react'
import back_image from './code_background.png';
import { motion } from 'framer-motion';
import FeaturesButtons from './FeaturesButtons';

interface IProps {
    featuresRef: React.Ref<HTMLDivElement>,
    setFeaturesSectionVisible: Function,
}

const cardVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

const ScreenTwo : React.FC<IProps> = ({featuresRef, setFeaturesSectionVisible} : IProps) => {
  return (
    <div className="w-screen relative h-fit" ref={featuresRef}>
        <div className="w-screen h-full items-center absolute" style={{ backgroundImage: `url(${back_image})`, backgroundSize: "contain", filter: "blur(2px)"}}></div>
        <div className="w-full h-full bg-vs-grey-4 absolute opacity-90 flex flex-col items-center" ></div>
        <div className="w-full h-full flex flex-col items-center" style={{opacity: 0.99}}>
            <div className="sm:w-screen md:w-3/4 lg:w-1/2 flex flex-col justify-center p-6" >
                <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className=" text-xl font-light bg-vs-grey-2 rounded-2xl p-6 flex flex-col my-12">
                    <div className="text-white text-sm mb-6">Simply add the Ekho tag into your code editor and threads will be rendered when you hover over them.</div>
                    <div>
                        <span className='text-vs-green-2' style={{letterSpacing: "2px"}}>{`<ekho thread={9} unread={1} chatId={"nd83H28jsk"} />`}</span>
                    </div>
                </motion.div>
                <div className=" text-xl font-light rounded-2xl py-6 flex flex-col " >
                    <FeaturesButtons setFeaturesSectionVisible={setFeaturesSectionVisible} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ScreenTwo