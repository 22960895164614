import React, { useEffect, useState } from 'react';
import echo_logo from './media/ekho_logo.png';
import { motion, useAnimationControls } from 'framer-motion';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoMdClose } from 'react-icons/io';
import BurgerMenuMenu from './BurgerMenuMenu';
import ReactGA from "react-ga4";

interface IProps {
    isMobile: boolean,
    isBurgerOpen: boolean,
    setIsBurgerOpen: Function,
    modalToggle: boolean,
    setModalToggle: Function,
    handleNavScroll: Function,
    featuresSectionVisible: boolean,
}

const AppHeader : React.FC<IProps> = ({isMobile, isBurgerOpen, setIsBurgerOpen, modalToggle, setModalToggle, handleNavScroll, featuresSectionVisible} : IProps ) => {

  const controls = useAnimationControls();

  function handleNavItemClick(navItem : string){
    handleNavScroll(navItem);
    if(navItem === "PRODUCT") {
      ReactGA.send({ hitType: "pageview", page: "/product" });
      return;
    }
    if(navItem === "FEATURES") {
      ReactGA.send({ hitType: "pageview", page: "/features" });
      return;
    }
    if(navItem === "PRICING") {
      ReactGA.send({ hitType: "pageview", page: "/pricing" });
      return;
    }
  }

  useEffect(() => {
    if(featuresSectionVisible) {
      controls.start({
        y: [0, -20, 0],
        boxShadow: ["0px 0px 0 rgba(0, 0, 0, 0)", "10px 20px 0px rgba(0, 0, 0, 0.2)", "0px 0px 0 rgba(0, 0, 0, 0)"],
      })
    }
  }, [featuresSectionVisible])

  return (
    <div className="w-screen fixed bg-vs-grey-4 flex flex-row justify-center drop-shadow-lg border-b border-slate-800 opacity-95" style={{height: "60px", zIndex: 50}}>
        {/* <BurgerMenuMenu isOpen={isOpen} setIsOpen={()=>setIsOpen(!isOpen)}/> */}
        <div className="flex flex-row items-center justify-between p-3 w-screen md:w-3/4 lg:w-1/2 ">
          <div className="flex flex-row cursor-pointeritems-center" onClick={()=>handleNavScroll("PRODUCT")}>
            <img src={echo_logo} width={32} className="mr-6 sm:ml-3" />
            <p className="text-white font-light text-xl hover:text-zinc-400" style={{letterSpacing: "2px"}}>Ekho</p>
          </div>
          {
            isMobile ?
            <div className="" onClick={()=>setIsBurgerOpen(!isBurgerOpen)}>
              {
                isBurgerOpen ?
                <IoMdClose size={25} color="white"/>
                :
                <RxHamburgerMenu size={35} color="white"/>
              }
            </div>
            :
            <div className="flex flex-row p-3 justify-between">
              <h1 className="text-white font-extralight ml-6 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleNavItemClick("PRODUCT")}>Product</h1>
              <h1 className="text-white font-extralight ml-6 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleNavScroll("FEATURES")}>Features</h1>
              <h1 className="text-white font-extralight ml-6 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleNavScroll("PRICING")}>Pricing</h1>
              {/* <h1 className="text-white font-extralight ml-6 text-md cursor-pointer hover:text-zinc-400">About</h1> */}
            </div>
          }
          <div className="absolute w-screen sm:w-screen md:w-3/4 lg:w-1/2 flex justify-end items-end" style={{top: "90px", height: "10px"}}>
            <motion.div animate={controls} transition={{ type: "spring", stiffness: 200 }} onClick={()=>setModalToggle(true)} whileHover={{scale: 1.1}} className="mr-6 ml-3 px-6 text-white text-sm font-extralight drop-shadow-2xl rounded-full bg-vs-blue-1 hover:bg-vs-blue-2 cursor-pointer" style={{top: "70px", left: "0px", paddingTop: "4px", paddingBottom: "4px"}}>
              Register Now
            </motion.div>
          </div>
        </div>
    </div>
  )
}

export default AppHeader