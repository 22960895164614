import React from 'react';
import ekho_logo from '../media/ekho_logo.png';
import { FaRegCopyright } from 'react-icons/fa';

interface IProps {
    handleNavScroll: Function,
    setContactUsModalToggle: Function,
}

const Footer : React.FC<IProps> = ({handleNavScroll, setContactUsModalToggle} : IProps) => {

    const footerLinks = [
        {name: "Product", action: ()=>handleNavScroll("PRODUCT")},
        {name: "Features", action: ()=>handleNavScroll("FEATURES")},
        {name: "Pricing", action: ()=>handleNavScroll("PRICING")},
        // {name: "About", action: ()=>console.log("")},
        {name: "Contact Us", action: ()=>setContactUsModalToggle(true)},
    ]

  return (
    <div className="w-full bg-vs-grey-2  border-t border-slate-600 flex justify-center items-center" style={{height: "200px"}}>
        <div className='w-screen sm:w-screen md:w-3/4 lg:w-1/2 flex flex-row justify-between p-6 md:p-3'>
            <div className='flex flex-col'>
                {
                    footerLinks.map(category => (
                        <div className='text-white hover:text-zinc-400' onClick={category.action}>
                            {category.name}
                        </div>
                    ))
                }
            </div>
            <div className='flex flex-col items-end'>
                <img src={ekho_logo} width={50} />
                <div className="flex flex-row text-white mt-3">
                    <div style={{letterSpacing: "4px"}}>Ekho</div>
                    <FaRegCopyright size={12} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer