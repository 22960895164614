import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import teaching_icon from '../media/teaching_icon.png'; 
import timezones_icon from '../media/timezones_icon.png'; 
import documentation_icon from '../media/documentation_icon.png'; 
import { Zoom } from '@mui/material';
import { motion } from "framer-motion";

interface IProps  {
    setFeaturesSectionVisible: Function,
}

const cardVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

const FeaturesButtons : React.FC<IProps> = ({setFeaturesSectionVisible} : IProps ) => {

    const myRef = useRef<HTMLDivElement>(null);

    const observer = new IntersectionObserver((entries, observer) => {
        const entry = entries[0];
        console.log(entry.isIntersecting);
        setFeaturesSectionVisible(entry.isIntersecting);
      });
    
    useEffect(() => {
        if(myRef.current) {
            observer.observe(myRef.current);
        }
    }, [])

  return (
    <div className="w-full flex flex-col justify-between items-start xl:flex-row" >
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='w-full xl:w-1/3 flex flex-col items-center'>
            <motion.div className="m-0 " style={{width: "75px", height: "75px", zIndex: 200}} 
                        whileHover={{ scale: 1.25 }}
                        whileTap={{ scale: 0.9 }}>
                <motion.div animate={{
                                scale: [1, 1.5, 1.5, 1, 1],
                                rotate: [0, 0, 270, 270, 0],
                                borderRadius: ["20%", "20%", "50%", "50%", "100%"],
                                }} 
                                transition={{duration: 2}}
                                className="bg-vs-purple-1 absolute rounded-full " style={{width: "75px", height: "75px"}}></motion.div>
                <div className='absolute flex justify-center items-center' style={{width: "75px", height: "75px"}}>
                    <img src={teaching_icon} width={45} className="" alt="Teaching Icon"/>
                </div>
            </motion.div>
            <div className="p-3">
                <h1 className='text-xl text-vs-purple-1 mb-6 mt-6 font-extrabold text-center xl:text-start' style={{height: "60px"}}>Knowledge Sharing</h1>
                <p className='text-white text-sm font-light mb-6 text-center xl:text-start'>Help senior devs coach and train more junior members of the team by asking and answering questions though the video and audio snippets.</p>
            </div>
        </motion.div>
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='w-full xl:w-1/3 flex flex-col items-center'>
            <motion.div className="m-0" style={{width: "75px", height: "75px", zIndex: 200}} 
                        whileHover={{ scale: 1.25 }}
                        whileTap={{ scale: 0.9 }}>
                <motion.div animate={{
                                scale: [1, 1.5, 1.5, 1, 1],
                                rotate: [0, 0, 270, 270, 0],
                                borderRadius: ["20%", "20%", "50%", "50%", "100%"],
                                }} 
                                transition={{duration: 2}}
                                className="bg-vs-blue-3 absolute rounded-full flex justify-center items-center drop-shadow-xl" style={{width: "75px", height: "75px"}}></motion.div>
                <div ref={myRef} className='absolute flex justify-center items-center' style={{width: "75px", height: "75px"}}>
                    <img src={timezones_icon} width={55} className="" alt="Timezone Icon"/>
                </div>
            </motion.div>
            <div className="p-3 rounded-2xl" style={{transform: "translateY(0px)"}}>
                <h1 className='text-xl text-vs-blue-3 mb-6 mt-6 font-extrabold text-center xl:text-start' style={{height: "60px"}}>Work Across Time Zones</h1>
                <p className='text-white text-sm font-light mb-6 text-center xl:text-start'>Communicate effectively across multiple zime zones, or team members working at different times of day.</p>
            </div>
        </motion.div>
        <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} viewport={{ once: true, amount: 0.2 }} className='w-full xl:w-1/3 flex flex-col items-center'>
            <motion.div className="m-0" style={{width: "75px", height: "75px", zIndex: 200}} 
                        whileHover={{ scale: 1.25 }}
                        whileTap={{ scale: 0.9 }}>
                <motion.div animate={{
                                scale: [1, 1.5, 1.5, 1, 1],
                                rotate: [0, 0, 270, 270, 0],
                                borderRadius: ["20%", "20%", "50%", "50%", "100%"],
                                }} 
                                transition={{duration: 2}}
                                className="bg-vs-green-2 absolute rounded-full flex justify-center items-center drop-shadow-xl" style={{width: "75px", height: "75px"}}></motion.div>
                <div className='absolute flex justify-center items-center' style={{width: "75px", height: "75px"}}>
                    <img src={documentation_icon} width={45} className="" alt="Documentation Icon"/>
                </div>
            </motion.div>
            <div className="p-3 rounded-2xl" style={{transform: "translateY(0px)"}}>
                <h1 className='text-xl text-vs-green-2 mb-6 mt-6 font-extrabold text-center xl:text-start' style={{height: "60px"}}>Bring Your Documentation To Life</h1>
                <p className='text-white text-sm font-light mb-6 text-center xl:text-start'>A picture tells 1000 words. A video tells even more. Bring life to your project documentation by adding audio or video snippets into to your code - exactly where it means something.</p>
            </div>
        </motion.div>

        
    </div>
  )
}

export default FeaturesButtons





{/* <Tooltip TransitionComponent={Zoom} title={
    <React.Fragment>
        <h1>Working Across Time Zones</h1>
        <p>Communicate effectively across multiple zime zones, or team members working at different times of day.</p>
    </React.Fragment>
}>
    <IconButton>
        <motion.div className="m-5" style={{width: "75px", height: "75px"}} 
                    whileHover={{ scale: 1.25 }}
                    whileTap={{ scale: 0.9 }}>
            <motion.div animate={{
                            scale: [1, 1.5, 1.5, 1, 1],
                            rotate: [0, 0, 270, 270, 0],
                            borderRadius: ["20%", "20%", "50%", "50%", "100%"],
                            }} 
                            transition={{duration: 2}}
                            className="bg-vs-grey-1 absolute rounded-full flex justify-center items-center drop-shadow-xl" style={{width: "75px", height: "75px"}}></motion.div>
            <div className='absolute flex justify-center items-center' style={{width: "75px", height: "75px"}}>
                <img src={timezones_icon} width={45} className="absolute" alt="Teaching Icon"/>
            </div>
        </motion.div>
    </IconButton>
</Tooltip>

<Tooltip TransitionComponent={Zoom} title={
    <React.Fragment>
        <h1>Bring Life To Documentation</h1>
        <p>A picture tells 1000 words. A video tells even more. Bring life to your project documentation by adding audio or video snippets into to your code - exactly where it means something.</p>
    </React.Fragment>
}>
    <IconButton>
        <motion.div className="m-5" style={{width: "75px", height: "75px"}} 
                    whileHover={{ scale: 1.25 }}
                    whileTap={{ scale: 0.9 }}>
            <motion.div animate={{
                            scale: [1, 1.5, 1.5, 1, 1],
                            rotate: [0, 0, 270, 270, 0],
                            borderRadius: ["20%", "20%", "50%", "50%", "100%"],
                            }} 
                            transition={{duration: 2}}
                            className="bg-vs-grey-1 absolute rounded-full flex justify-center items-center drop-shadow-xl" style={{width: "75px", height: "75px"}}></motion.div>
            <div className='absolute flex justify-center items-center' style={{width: "75px", height: "75px"}}>
                <img src={documentation_icon} width={45} className="absolute" alt="Teaching Icon"/>
            </div>
        </motion.div>
    </IconButton>
</Tooltip> */}