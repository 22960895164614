import React from 'react'
import IntroductionMessage from './IntroductionMessage';
import demo_image from '../media/product_example_photo.png';
import demo_image_mobile from '../media/product_example_mobile.png';
import { motion } from 'framer-motion';

interface IProps {
  isMobile: boolean,
  setModalToggle: Function,
  productRef: React.Ref<HTMLDivElement>,
}

const cardVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const ScreenOne : React.FC<IProps> = ({isMobile, setModalToggle, productRef} : IProps ) => {
  return (
    <div className="bg-vs-grey-4 sm:w-screen md:w-3/4 lg:w-1/2"  >
        <div className="flex flex-col p-3 py-12 w-full h-fit items-center justify-center" ref={productRef}>
          <IntroductionMessage isMobile={isMobile} setModalToggle={setModalToggle}/>
          <motion.div initial="offscreen" whileInView="onscreen" variants={cardVariants} className="w-full flex justify-center items-center p-3" viewport={{ once: true, amount: 0.2 }}>
            <img src={isMobile ? demo_image_mobile : demo_image} className="mt-12 w-full rounded-xl"/>
          </motion.div>
        </div>
    </div>
  )
}

export default ScreenOne