import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoMdClose } from 'react-icons/io';
import echo_logo from './media/ekho_logo.png';
import ReactGA from "react-ga4";

interface IProps {
    isOpen: boolean,
    setIsOpen: Function,
    handleNavScroll: Function
}

const BurgerMenuMenu : React.FC<IProps> = ({isOpen, setIsOpen, handleNavScroll} : IProps ) => {

    function handleSelction(selection : string){
        handleNavScroll(selection);
        setIsOpen();
        if(selection === "PRODUCT") {
            ReactGA.send({ hitType: "pageview", page: "/product" });
            return;
          }
          if(selection === "FEATURES") {
            ReactGA.send({ hitType: "pageview", page: "/features" });
            return;
          }
          if(selection === "PRICING") {
            ReactGA.send({ hitType: "pageview", page: "/pricing" });
            return;
          }
    }

  return (
    <AnimatePresence>
        {
            isOpen && (
                <motion.div 
                    initial={{left: -300 }}
                    animate={{left: 0, }}
                    exit={{left: -300 }}
                    className="fixed h-screen bg-vs-grey-2 flex flex-col rounded-r-xl p-6" 
                    style={{width: "200px", left: "0px", zIndex: 100, opacity: 1}}>
                        <div className="flex flex-row justify-between">
                            <img src={echo_logo} width={32} className="mr-6 sm:ml-3" />
                            <IoMdClose size={25} color="white" onClick={()=>setIsOpen()}/>
                        </div>
                        <div className='flex flex-col py-6'>
                            <h1 className="text-white font-extralight mr-6 mb-3 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleSelction("PRODUCT")}>Product</h1>
                            <h1 className="text-white font-extralight mr-6 mb-3 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleSelction("FEATURES")}>Features</h1>
                            <h1 className="text-white font-extralight mr-6 mb-3 text-md cursor-pointer hover:text-zinc-400" onClick={()=>handleSelction("PRICING")}>Pricing</h1>
                            {/* <h1 className="text-white font-extralight mr-6 mb-3 text-md cursor-pointer hover:text-zinc-400">About</h1> */}
                        </div>
                </motion.div>
            )
        }
    </AnimatePresence>
  )
}

export default BurgerMenuMenu