import React, { useRef, useState } from 'react'
import AppHeader from './AppHeader'
import AppWindow from './AppWindow'
import BurgerMenuMenu from './BurgerMenuMenu'

interface IProps {
  isMobile: boolean,
}

const UnAuthenticatedRoute : React.FC<IProps> = ({isMobile} : IProps ) => {

  const [ isBurgerOpen, setIsBurgerOpen ] = useState<boolean>(false);
  const [ modalToggle, setModalToggle ] = useState<boolean>(false);
  const [ featuresSectionVisible, setFeaturesSectionVisible ] = useState<boolean>(false);

  const productRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  
  function handleNavScroll(scrollPosition : string){
    if(scrollPosition === "PRODUCT" && productRef && productRef.current){
      productRef?.current.scrollIntoView({behavior: "smooth"});
      return;
    }
    if(scrollPosition === "FEATURES" && featuresRef && featuresRef.current){
      featuresRef?.current.scrollIntoView({behavior: "smooth"});
      return;
    }
    if(scrollPosition === "PRICING" && pricingRef && pricingRef.current){
      pricingRef?.current.scrollIntoView({behavior: "smooth"});
      return;
    }
  }

  
  return (
    <div className="w-screen h-screen bg-vs-grey-4">
        <BurgerMenuMenu isOpen={isBurgerOpen} setIsOpen={()=>setIsBurgerOpen(!isBurgerOpen)} handleNavScroll={handleNavScroll}/>
        <AppHeader isMobile={isMobile} isBurgerOpen={isBurgerOpen} setIsBurgerOpen={setIsBurgerOpen} modalToggle={modalToggle} setModalToggle={setModalToggle} handleNavScroll={handleNavScroll} featuresSectionVisible={featuresSectionVisible}/>
        <AppWindow isMobile={isMobile} modalToggle={modalToggle} setModalToggle={setModalToggle} productRef={productRef} featuresRef={featuresRef} pricingRef={pricingRef} handleNavScroll={handleNavScroll} setFeaturesSectionVisible={setFeaturesSectionVisible}/>
    </div>
  )
}

export default UnAuthenticatedRoute