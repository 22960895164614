import { Alert, Backdrop, Button, Dialog, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ReactGA from "react-ga4";
import { redirect } from 'react-router';
import { useNavigate } from 'react-router';

//Firebase
import   firebaseApp   from "../../firebase-config.js";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { BsCheck2Circle } from 'react-icons/bs';



interface IProps {
    modalToggle: boolean,
    setModalToggle: Function,
    proposedPlan: string,
}

const SignupModal : React.FC<IProps> = ({modalToggle, setModalToggle, proposedPlan} : IProps) => {

    const db = getFirestore(firebaseApp.firebaseApp);
    const navigate = useNavigate();

    const [ userName, setUserName ] = useState<string>("");
    const [ userEmail, setUserEmail ] = useState<string>("");
    const [ errorMessage, setErrorMessage ] = useState<string>("");
    const [ submitSuccessToggle, setSubmitSuccessToggle ] = useState<boolean>(false);
    const [ mostLikelyPlan, setMostLikelyPlan ] = useState<string>(proposedPlan);

    useEffect(() => {
        if(modalToggle) {
            ReactGA.event({
                category: "SignupModal",
                action: "Opened",
                label: "Toggled Signup Modal", // optional
              });
        }
    }, [modalToggle])

    async function postUserToDatabase(){
        if(userName && userEmail) {
            const newUserRef = await addDoc(collection(db, "PreLaunchList"), {
                name: userName,
                email: userEmail,
                mostLikelyPlan: mostLikelyPlan,
            });
            setSubmitSuccessToggle(true);
            return; 
        } else {
            if(!userName) {
                setErrorMessage("Please enter a valid name");
                return;
            }
            if(!userEmail) {
                setErrorMessage("Please enter a valid email address");
                return;  
            } 
        }   
    }

    function handleUserName(e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        setUserName(e.target.value);
        setErrorMessage("");
    }

    function handleUserEmail(e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        setUserEmail(e.target.value);
        setErrorMessage("");
    }

    function handleSubmitForm() {
        ReactGA.event({
            category: "SignupModal",
            action: "Submitted",
            label: "Submitted Signup Modal", 
          });
        postUserToDatabase().then((res) => {
            navigate("/registered")
        });
    }

  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modalToggle}
        onClick={(e)=>{
            e.stopPropagation();
            setModalToggle(false);
        }}
        >
            {
                !submitSuccessToggle 
                ?
                <div className="w-screen sm:w-screen md:w-2/3 lg:w-2/3 xl:w-1/3 p-6" onClick={(e)=>e.stopPropagation()}>
                    <div className="bg-zinc-200 w-full h-1/2 rounded-xl p-6 text-vs-grey-4 flex flex-col items-center">
                        <div className='text-3xl font-bold'>Pre-launch Sign Up</div>
                        <div className='text-lg font-extralight text-center mt-3 mb-3'>Ekho will be launching shortly. If you register now, you will get <b>30% off</b>  your subscription after launch! 💸</div>
                        <TextField id="outlined-basic" label="Name" variant="outlined" onChange={handleUserName} className='w-full' sx={{marginBottom: "20px", marginTop: "20px"}}/>
                        <TextField id="outlined-basic" label="Email" variant="outlined" onChange={handleUserEmail} className='w-full' sx={{marginBottom: "20px", marginTop: "20px"}}/>
                        <FormControl fullWidth sx={{marginTop: "20px", marginBottom: "20px"}}>
                            <InputLabel id="demo-simple-select-label">Most Likely Plan</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={mostLikelyPlan}
                                label="Most Likely Plan"
                                onChange={(e)=>setMostLikelyPlan(e.target.value as string)}
                            >
                                <MenuItem value={"Starter"}>Starter</MenuItem>
                                <MenuItem value={"Scale Up"}>Scale Up</MenuItem>
                                <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
                            </Select>
                        </FormControl>
                        { errorMessage.length > 1 && <Alert className='w-full' severity="error">{errorMessage}</Alert>}
                        <motion.div whileTap={{scale: 0.9}} onClick={handleSubmitForm} className='w-full bg-vs-blue-1 rounded flex justify-center items-center text-white hover:bg-vs-blue-2' style={{height: "50px", marginTop: "20px"}}>Keep me updated!</motion.div>
                    </div>
                </div>
                :
                <div className="w-screen sm:w-screen md:w-2/3 lg:w-2/3 xl:w-1/3 p-6" onClick={(e)=>e.stopPropagation()}>
                    <div className="bg-zinc-200 w-full h-1/2 rounded-xl p-6 text-vs-grey-4 flex flex-col items-center">
                        <div className='text-3xl font-bold text-center'>
                            🎉 You have successfully registered to Ekho! 
                        </div>
                        <div className='w-full h-full flex justify-center items-center p-6' >
                            <div className='w-full h-full flex justify-center items-center'>
                                <motion.div
                                    style={{ width: "125px", height: "125px", borderWidth: "2px", borderColor: "green", display: "flex", justifyContent: "center", alignItems: "center" }}
                                    animate={{
                                        scale: [1, 1.2, 1.2, 1, 1],
                                        rotate: [0, 0, 270, 270, 0],
                                        borderRadius: ["100%", "20%", "50%", "50%", "100%"],
                                    }}
                                    transition={{ delay: 0.5, repeat: 0, duration: 3 }}
                                >
                                    <motion.div
                                        style={{ width: "100px", height: "100px", borderWidth: "2px", borderColor: "green", display: "flex", justifyContent: "center", alignItems: "center" }}
                                        animate={{
                                            scale: [1, 1.2, 1.2, 1, 1],
                                            rotate: [22.5, 22.5, 292.5, 292.5, 22.5],
                                            borderRadius: ["100%", "20%", "50%", "50%", "100%"],
                                        }}
                                        transition={{ delay: 0.5, repeat: 0, duration: 3 }}
                                    >
                                        <motion.div
                                            style={{ width: "75px", height: "75px", borderWidth: "2px", borderColor: "green", display: "flex", justifyContent: "center", alignItems: "center" }}
                                            animate={{
                                                scale: [1, 1.2, 1.2, 1, 1],
                                                rotate: [45, 45, 315, 315, 45],
                                                borderRadius: ["100%", "20%", "50%", "50%", "100%"],
                                            }}
                                            transition={{ delay: 0.5, repeat: 0, duration: 3 }} />
                                    </motion.div>
                                </motion.div>
                                <BsCheck2Circle size={50} color="green" style={{ position: "absolute" }} />
                            </div>
                        </div>
                        <div className='text-xl font-extralight text-center mb-3'>
                            We will be in touch shortly.
                        </div>
                        <div>
                            <Button onClick={() => setModalToggle(false) } autoFocus variant="contained" color="success">
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            }
    </Backdrop>
  )
}

export default SignupModal;