import { Backdrop } from '@mui/material';
import React from 'react'

interface IProps {
    contactUsModalToggle: boolean,
    setContactUsModalToggle: Function,
}

const ContactUsModal : React.FC<IProps> = ({contactUsModalToggle, setContactUsModalToggle} : IProps) => {
  return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={contactUsModalToggle}
        onClick={(e)=>{
            e.stopPropagation();
            setContactUsModalToggle(false);
        }}
        >
        <div className="w-screen sm:w-screen md:w-2/3 lg:w-2/3 xl:w-1/3 p-6" onClick={(e)=>e.stopPropagation()}>
            <div className="bg-zinc-200 w-full h-1/2 rounded-xl p-6 text-vs-grey-4 flex flex-col items-center">
                <div className='text-3xl font-bold'>Get In Touch</div>
                <div className='text-lg font-extralight text-center mt-3 mb-3'>We would love for you to get in touch with any questions or suggestions. Email us at <b>ekhochat@gmail.com</b>  and we will get back to you as soon as possible. 👋 </div>
            </div>
        </div>
    </Backdrop>
  )
}

export default ContactUsModal